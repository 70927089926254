import { graphql, useStaticQuery } from "gatsby";

export default function Nav({ className }) {

	const { allRoutesJson: { nodes: routes } } = useStaticQuery(graphql`
		query {
			allRoutesJson {
				nodes {
					title
					slug
					label
				}
			}
		}
	`);

	return <nav {...{ className }}>
		<div className="navbar-brand is-flex-wrap-wrap has-text-weight-bold">
			{routes.map(({ title, slug, label }) => {
				if (slug !== "/") slug = `/${slug}`;
				return <a key={slug} className="navbar-item" href={slug} title={title}>
					{label}
				</a>
			})}
		</div>
	</nav>;
}