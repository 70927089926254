import classNames from "classnames";

import Section from "@wrappers/Section";

import { Facebook, GoogleMapsPin } from "@elements/Icon";

import Nav from "./Nav";

export default function Header() {
	return <header className="is-relative">
		<div id="gms-top-layout" className="is-flex">
			<a href="/" title="חזרה לעמוד הבית">
				<Logo style={{ width: '10rem' }} />
			</a>
			<HeaderRow className="has-background-danger-light-above-tablet">
				<Nav className="is-flex is-flex-wrap-wrap has-background-danger-light-above-tablet" />
				<div className="is-flex">
					<a className="is-flex onhover-link mx-1 facebook" href="https://www.facebook.com/SamarDesertGarden" target="_blank" rel="noreferrer">
						<Facebook className="is-align-self-center" />
					</a>
					<a className="is-flex onhover-link mx-1 google-maps" href="https://goo.gl/maps/VVJL3oXmsbDVoGR27" target="_blank" rel="noreferrer">
						<GoogleMapsPin className="is-align-self-center" />
					</a>
				</div>
			</HeaderRow>
		</div>
	</header>;
}

function Logo({ className: classes, style: styles }) {
	const className = classNames("is-block", classes);
	const style = Object.assign({ boxShadow: '0 0 20px 5px white', borderRadius: '100%' }, styles);
	return <img id="top-logo" src="https://storage.googleapis.com/garden-www/assets/logo-01.png" {...{ className, style }} alt="לוגו גן מדבר" />;
}

function HeaderRow({ className: classes, children }) {
	const className = classNames("outer-section", classes);
	return <div {...{ className }} style={{ width: '100%' }}>
		<Section withTopMargin={false} withSidePadding={false} className="is-flex is-justify-content-space-between is-align-items-center" style={{ width: '100%' }}>
			{children}
		</Section>
	</div>;
}