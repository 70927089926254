import Icon from "./Icon";

export const Checkmark = (props) => <span {...props}>&#10003;</span>;

export const Email = (props) => <Icon size={456} {...props}>
	<path d="M52.616,139.032l102.427,88.898L52.616,316.828c-6.162,5.349-6.823,14.68-1.474,20.843     c5.349,6.162,14.68,6.821,20.842,1.474l105.6-91.651l40.663,35.292c2.778,2.411,6.231,3.616,9.684,3.616s6.906-1.205,9.684-3.616     l40.663-35.292l105.6,91.651c6.162,5.348,15.493,4.688,20.842-1.474c5.349-6.163,4.688-15.494-1.474-20.843L300.819,227.93     l102.427-88.898c6.163-5.349,6.823-14.68,1.474-20.843c-5.349-6.163-14.682-6.822-20.842-1.474     c-8.592,7.457-146.799,127.408-155.947,135.348c-9.151-7.942-147.358-127.893-155.947-135.348     c-6.162-5.348-15.494-4.687-20.842,1.474C45.793,124.352,46.454,133.683,52.616,139.032z" />
	<path d="M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.929v300.003c0,8.16,6.615,14.774,14.774,14.774h426.313     c8.16,0,14.774-6.614,14.774-14.774V77.929C455.862,69.77,449.248,63.154,441.088,63.154z M426.314,363.157h-0.001H29.549V92.703     h396.765V363.157z" />
</Icon>;

export const Facebook = (props) => <Icon size={60} {...props}>
	<path xmlns="http://www.w3.org/2000/svg"
		d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914   v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462   v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z" />
</Icon>;

export const GoogleMaps = (props) => <Icon size={24} {...props}>
	<g transform="scale(1.03, 1.03), translate(3.5, 0)">
		<path fill='#1a73e8' d="M14.45.78A8.09,8.09,0,0,0,5.8,3.29L9.63,6.51Z" transform="translate(-3.91 -0.4)" />
		<path fill='#ea4335' d="M5.8,3.29a8.07,8.07,0,0,0-1.89,5.2,9.06,9.06,0,0,0,.8,3.86L9.63,6.51Z" transform="translate(-3.91 -0.4)" />
		<path fill='#4285f4' d="M12,5.4a3.09,3.09,0,0,1,3.1,3.09,3.06,3.06,0,0,1-.74,2l4.82-5.73a8.12,8.12,0,0,0-4.73-4L9.63,6.51A3.07,3.07,0,0,1,12,5.4Z" transform="translate(-3.91 -0.4)" />
		<path fill='#fbbc04' d="M12,11.59a3.1,3.1,0,0,1-3.1-3.1,3.07,3.07,0,0,1,.73-2L4.71,12.35A28.67,28.67,0,0,0,8.38,17.6l6-7.11A3.07,3.07,0,0,1,12,11.59Z" transform="translate(-3.91 -0.4)" />
		<path fill='#34a853' d="M14.25,19.54c2.7-4.22,5.84-6.14,5.84-11a8.1,8.1,0,0,0-.91-3.73L8.38,17.6c.46.6.92,1.24,1.37,1.94C11.4,22.08,10.94,23.6,12,23.6S12.6,22.08,14.25,19.54Z" transform="translate(-3.91 -0.4)" />
	</g>
</Icon>;

export const GoogleMapsPin = (props) => <Icon size={362} {...props}>
	<path d="m181.065,0c-75.532,0-136.981,61.45-136.981,136.981 0,31.155 21.475,76.714 63.827,135.411 30.619,42.436 60.744,75.965 62.011,77.372l11.144,12.367 11.144-12.367c1.267-1.406 31.392-34.936 62.011-77.372 42.352-58.697 63.827-104.255 63.827-135.411-0.001-75.531-61.451-136.981-136.983-136.981zm0,316.958c-37.733-44.112-106.981-134.472-106.981-179.977 0-58.989 47.991-106.981 106.981-106.981s106.981,47.992 106.981,106.981c0.001,45.505-69.248,135.865-106.981,179.977z" />
	<circle cx="181.065" cy="136.982" r="49.696" />
</Icon>;

export const HalfStar = (props) => <Icon size={36} data-rtl-flip {...props}>
	<path fill='#e37400' xmlns="http://www.w3.org/2000/svg" d="M36.043,13.909c-0.123-0.377-0.456-0.646-0.852-0.688l-11.549-1.171L18.96,1.431c-0.159-0.363-0.518-0.596-0.914-0.596   s-0.755,0.234-0.915,0.597l-4.683,10.62L0.9,13.222c-0.395,0.04-0.728,0.309-0.851,0.688c-0.122,0.377-0.011,0.791,0.285,1.055   l8.651,7.734L6.532,34.044c-0.084,0.387,0.07,0.789,0.391,1.021c0.321,0.231,0.747,0.254,1.091,0.055l10.031-5.838l10.029,5.838   c0.156,0.092,0.33,0.137,0.505,0.137c0.207,0,0.411-0.064,0.588-0.191c0.319-0.231,0.474-0.633,0.39-1.021L27.104,22.7l8.651-7.735   C36.054,14.698,36.165,14.286,36.043,13.909z M25.339,21.594c-0.271,0.24-0.39,0.604-0.312,0.957l2.099,9.699l-8.574-4.99   c-0.001,0-0.002,0-0.003-0.002V5.447l3.503,7.943c0.146,0.33,0.456,0.555,0.813,0.592l9.869,1.001L25.339,21.594z" />
</Icon>;

export const Outbound = (props) => <Icon size={33} {...props}>
	<path id="Path_89" data-name="Path 89" d="M24,22v5a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V9A1,1,0,0,1,5,8h5a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H3A3,3,0,0,0,0,7V29a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V22a2,2,0,0,0-2-2h0A2,2,0,0,0,24,22Z" fill="#161615" />
	<rect id="Rectangle_40" data-name="Rectangle 40" width="16" height="4" rx="2" transform="translate(16 0)" fill="#161615" />
	<rect id="Rectangle_41" data-name="Rectangle 41" width="16" height="4" rx="2" transform="translate(32 0) rotate(90)" fill="#161615" />
	<rect id="Rectangle_42" data-name="Rectangle 42" width="32.296" height="3.971" rx="1.986" transform="translate(7.178 22.014) rotate(-45)" fill="#161615" />
</Icon>;

export const Search = (props) => <Icon size={487} {...props}>
	<path d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1    c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4    c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z" />
</Icon>;

export const Star = (props) => <Icon size={35} {...props}>
	<path fill='#e37400' xmlns="http://www.w3.org/2000/svg" d="M34.921,14.075l-8.87,7.93l2.517,11.628c0.042,0.193-0.035,0.394-0.195,0.511c-0.087,0.062-0.189,0.097-0.294,0.097   c-0.086,0-0.173-0.022-0.251-0.067l-10.284-5.986L7.26,34.172c-0.172,0.099-0.385,0.088-0.545-0.028   c-0.16-0.115-0.236-0.314-0.194-0.51l2.515-11.628l-8.87-7.93c-0.147-0.134-0.203-0.341-0.142-0.527   c0.061-0.188,0.228-0.322,0.425-0.343l11.837-1.201l4.8-10.887c0.16-0.361,0.755-0.361,0.915,0l4.799,10.887l11.838,1.201   c0.196,0.021,0.363,0.152,0.426,0.343C35.124,13.735,35.068,13.94,34.921,14.075z" />
</Icon>;