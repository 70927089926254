import Header from "@config/Header";
import Footer from "@config/Footer";

export default function Page({ children }) {

	return <>
		<Header />

		<main>
			{children}
		</main>

		<Footer />
	</>
}